import React from "react"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <div>Alp Kahvecioglu</div>
        <div>Istanbul, Turkey</div>
        <div>(willing to relocate!)</div>
      </div>
      <div className="footer-right">
        <a href="mailto:aakahvecioglu@gmail.com" style={{color: "#000", textDecoration:"underline"}}>aakahvecioglu(at)gmail.com</a>
        <div>@dyslexicat or @enpassant</div>
      </div>
    </footer>
  )
}

export default Footer
