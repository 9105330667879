import React from "react"
import { Link } from "gatsby"

export default function Header() {
  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/" activeClassName="active">
            home
          </Link>
        </li>
        <li>
          <Link to="/projects" activeClassName="active">
            projects
          </Link>
        </li>
        <li>
          <Link to="/links" activeClassName="active">
            links
          </Link>
        </li>
        <li>
          <Link to="/blog" activeClassName="active">
            blog
          </Link>
        </li>

        <li className="logo">
          <Link to="/">enpassant</Link>
        </li>
      </ul>
    </nav>
  )
}
